.communityImage {
  margin-top: 10px;
  margin-bottom: 10px;
  --avatar-size: 60px;
  height: var(--avatar-size);
  width: var(--avatar-size);
}
.locationsLabel {
  height: 30px;
}
.itemIconAvatar {
  background-color: unset;
}
.infoIcon {
  font-size: 12px;
}
