.avatar {
  background-color: var(--ion-color-light);
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 60%;
  height: 60%;
}
