.container {
  font-family: var(--ion-font-family);
  background: var(--ion-color-base);
  color: var(--ion-color-contrast);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  line-height: calc(var(--avatar-font-ratio) * var(--avatar-size));
  font-weight: 600;
  font-size: calc(var(--avatar-font-ratio) * var(--avatar-size));
}
