.messageItem {
  padding-left: 12px;
  padding-right: 58px;
  display: flex;
}

.messageItem {
  --avatar-height: 35px;
  min-height: var(--avatar-height);
}

.messageItem_fromSelf {
  flex-direction: row-reverse;
  padding-left: 58px;
  padding-right: 12px;
}

.messageItem_deleted {
  opacity: 0.4;
}

.messageItem_avatarVisible::part(native) {
  align-items: start;
}

.messageItem_spaceBefore {
  padding-top: 16px;
}

.messageItemSide {
  margin: 0;
  width: calc(var(--avatar-height) + 8px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-shrink: 0;
  text-align: center;
}

.messageItem_fromSelf .messageItemSide {
  display: none;
}

.messageItemName {
  padding-bottom: 4px;
  padding-left: 4px;
  padding-right: 4px;
}

.messageItemSide ion-avatar {
  margin: 0;
  width: var(--avatar-height);
  height: var(--avatar-height);
}

.messageItemContent {
  padding-right: 8px;
  padding-bottom: 4px;
}

.messageItem_fromSelf .messageItemContent {
  padding-right: 0;
  padding-left: 8px;
}

.messageItemTextWrapper {
  background: var(--ion-card-background);
  border-radius: 12px;
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
}

.messageItemText * {
  word-break: break-word;
  text-align: left;
  margin: 0;
}

.messageItemText p:not(:last-child) {
  padding-bottom: 0.5em;
}

.messageItemTimestamp {
  flex-shrink: 0;
  align-self: end;
  opacity: 0.5;
  margin-left: auto;
  padding-left: 4px;
}

.messageItemTimestamp small {
  font-size: 60%;
}

.messageItemTranslationIndicator {
  padding: calc(var(--ion-padding) / 2);
  padding-inline-start: 0;
  opacity: 0.6;
}

.messageItemAttachment {
  font-size: 80%;
  text-decoration: none;
  padding: 8px;
  border-radius: 8px;
  display: flex;
  font-weight: bold;

  background-color: var(--ion-color-light);
  transition: background-color 200ms linear;
}

.messageItemAttachment_loading {
  color: var(--ion-color-medium);
}

.messageItemAttachmentIcon {
  flex-shrink: 0;
  align-self: center;
  width: 24px;
}

.messageItemAttachmentIcon > * {
  width: 20px;
  height: 20px;
}

.messageAttachmentsContainer {
  padding-bottom: 4px;
}

.messageItemAttachment_clickable {
  color: var(--ion-color-primary);
  cursor: pointer;
}

.messageItemAttachment_clickable:focus {
  background-color: var(--ion-color-light-shade);
}

.messageItemAttachmentWrapper {
  padding-bottom: 4px;
}
