.background {
  --background: linear-gradient(#d06cb4, #fac450);
  background: var(--background);
  --ion-color-primary: #2a134f;
  --ion-color-primary-rgb: 42, 19, 79;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #251146;
  --ion-color-primary-tint: #3f2b61;
}

.toolbar {
  --background: transparent;
}

.title {
  font-size: 34px;
  font-weight: 700;
  line-height: 1.2em;
}
.icon {
  color: var(--ion-color-info-card-body-text);
  font-size: 50px;
  cursor: pointer;
}
.header {
  display: flex;
  justify-content: space-between;
}
