.poweredByGoogle {
  background-image: url("/public/assets/google/light_mode.png");
  background-position: right;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

@media (prefers-color-scheme: dark) {
  .poweredByGoogle {
    background-image: url("/public/assets/google/dark_mode.png");
  }
}
