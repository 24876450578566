.iconAvatar {
  overflow: unset;
}

:global(.ios) .channelAvatar {
  --avatar-size: 36px;
}

:global(.md) .channelAvatar {
  --avatar-size: 40px;
}

.isActive {
  --ion-item-background: var(--ion-color-light);
}

.messagePreviewIcon {
  vertical-align: sub;
}

.markdown {
  margin: 0;
  font-size: inherit;
  line-height: inherit;
  text-overflow: inherit;
  overflow: inherit;
}
