.background {
  --ion-text-color: #ffffff;
  --ion-background-color: linear-gradient(#d06cb4, #fac450);
  background: var(--ion-background-color);
  --ion-color-primary: #2a134f;
  --ion-color-primary-rgb: 42, 19, 79;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #251146;
  --ion-color-primary-tint: #3f2b61;
}

.activeBullet {
  --bullet-background: var(--ion-color-primary-shade);
  opacity: 1;
}

.outerWrapper {
  height: 100%;
  --bullet-background: var(--ion-color-primary);
}

.innerWrapper {
  padding: 0 32px 20px 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.innerWrapper h1 {
  font-size: 28px;
  font-weight: 700;
}
.innerWrapper p {
  max-width: 400px;
}

.illustrationWrapper {
  display: flex;
  flex-direction: column;
}
.illustrationWrapper img {
  min-height: 0;
  object-fit: contain;
  max-width: 320px;
}

.toolbar {
  --background: transparent;
}
