.list {
  list-style: none;
  padding: 0;
  align-items: center;
}

.content,
.list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: fit-content;
}
.illustrationWrapper {
  width: 80%;
  margin: 0 auto;
}
.houseImage {
  margin-top: 30px;
  margin-left: 12%;
}
