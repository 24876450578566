/*
  Workaround for https://github.com/ionic-team/ionic-framework/issues/19368
  When an input has focus and you press enter it should submit the form, but due
  to an issue with how Ionic uses the shadow DOM, it doesn't
*/
.submitFormWorkaround {
  position: absolute;
  visibility: hidden;
  left: -100000px;
}
