.teamSelect {
  padding-left: 0;
  padding-right: 0;
}
.teamSelect::part(icon) {
  display: none;
}

:global(.ios) .title {
  padding-left: 16px;
  text-align: left;
}

.profileAvatar {
  --avatar-size: 32px;
  height: var(--avatar-size);
  width: var(--avatar-size);
}
@media (prefers-color-scheme: light) {
  .bannerContainer {
    opacity: 1;
    background: linear-gradient(
      180deg,
      rgba(170, 170, 170, 0.146826) 0%,
      rgba(137, 102, 193, 0) 100%
    );
  }
}
@media (prefers-color-scheme: dark) {
  :global(.md) .bannerContainer {
    background-color: var(--ion-item-background);
  }
}
.bannerContainer ion-card ion-card-header {
  padding-bottom: 0;
}
.bannerContainer ion-card {
  background-color: var(--ion-color-banner-body-muted-aubergine);
}
.icon,
.superTitle {
  color: var(--ion-color-info-card-body-text) !important;
}
.icon {
  left: 10px;
  bottom: 10px;
  position: relative;
}
.cardText {
  margin-bottom: 0 !important;
}
