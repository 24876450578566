.userProfileAvatar {
  height: var(--avatar-size);
  width: var(--avatar-size);
  margin: 0 auto calc(var(--avatar-size) / -2) auto;
  transform: translateX(30%);
  z-index: 2;
  position: relative;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.illustrationWrapper {
  width: 80%;
  margin: 0 auto;
  --avatar-size: 170px;
}

.houseImage {
  max-width: calc(var(--avatar-size) * 0.95) !important;
  transform: translateX(-20%);
}
