.icon {
  border-radius: 50%;
  border: none;
  background: white;
  font-size: 38px;
  margin-left: -26px;
}

.icon:first-of-type {
  margin-left: 0;
}
