.root {
  overflow-anchor: none;
}

.messagePreviewIcon {
  vertical-align: sub;
}
.iconAvatar {
  overflow: unset;
}

:global(.ios) .channelAvatar {
  --avatar-size: 36px;
}

:global(.md) .channelAvatar {
  --avatar-size: 40px;
}

.isActive {
  --ion-item-background: var(--ion-color-light);
}
