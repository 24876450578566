/* In this file are custom defined ionic color classes that contain our brand colors.
These are required by ionic, so the colors defined in variables can be actually assigned via the color property. */

/** Custom "Starburst" Colors **/

/* sky-blue */
.ion-color-sky-blue {
  --ion-color-base: var(--ion-color-sky-blue);
  --ion-color-base-rgb: var(--ion-color-sky-blue-rgb);
  --ion-color-contrast: var(--ion-color-sky-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-sky-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-sky-blue-shade);
  --ion-color-tint: var(--ion-color-sky-blue-tint);
}

.ion-color-kiwi {
  --ion-color-base: var(--ion-color-kiwi);
  --ion-color-base-rgb: var(--ion-color-kiwi-rgb);
  --ion-color-contrast: var(--ion-color-kiwi-contrast);
  --ion-color-contrast-rgb: var(--ion-color-kiwi-contrast-rgb);
  --ion-color-shade: var(--ion-color-kiwi-shade);
  --ion-color-tint: var(--ion-color-kiwi-tint);
}

/* red */
.ion-color-salmon-pink {
  --ion-color-base: var(--ion-color-salmon-pink);
  --ion-color-base-rgb: var(--ion-color-salmon-pink-rgb);
  --ion-color-contrast: var(--ion-color-salmon-pink-contrast);
  --ion-color-contrast-rgb: var(--ion-color-salmon-pink-contrast-rgb);
  --ion-color-shade: var(--ion-color-salmon-pink-shade);
  --ion-color-tint: var(--ion-color-salmon-pink-tint);
}

/* orange */
.ion-color-tangerine {
  --ion-color-base: var(--ion-color-tangerine);
  --ion-color-base-rgb: var(--ion-color-tangerine-rgb);
  --ion-color-contrast: var(--ion-color-tangerine-contrast);
  --ion-color-contrast-rgb: var(--ion-color-tangerine-contrast-rgb);
  --ion-color-shade: var(--ion-color-tangerine-shade);
  --ion-color-tint: var(--ion-color-tangerine-tint);
}

/* yellow */
.ion-color-mango {
  --ion-color-base: var(--ion-color-mango);
  --ion-color-base-rgb: var(--ion-color-mango-rgb);
  --ion-color-contrast: var(--ion-color-mango-contrast);
  --ion-color-contrast-rgb: var(--ion-color-mango-contrast-rgb);
  --ion-color-shade: var(--ion-color-mango-shade);
  --ion-color-tint: var(--ion-color-mango-tint);
}

/* magenta */
.ion-color-magenta {
  --ion-color-base: var(--ion-color-magenta);
  --ion-color-base-rgb: var(--ion-color-magenta-rgb);
  --ion-color-contrast: var(--ion-color-magenta-contrast);
  --ion-color-contrast-rgb: var(--ion-color-magenta-contrast-rgb);
  --ion-color-shade: var(--ion-color-magenta-shade);
  --ion-color-tint: var(--ion-color-magenta-tint);
}

/* aubergine-fill */
.ion-color-aubergine-fill {
  --ion-color-base: var(--ion-color-aubergine-fill);
  --ion-color-base-rgb: var(--ion-color-aubergine-fill-rgb);
  --ion-color-contrast: var(--ion-color-aubergine-fill-contrast);
  --ion-color-contrast-rgb: var(--ion-color-aubergine-fill-contrast-rgb);
  --ion-color-shade: var(--ion-color-aubergine-fill-shade);
  --ion-color-tint: var(--ion-color-aubergine-fill-tint);
}

/* aubergine-text */
.ion-color-aubergine-text {
  --ion-color-base: var(--ion-color-aubergine-text);
  --ion-color-base-rgb: var(--ion-color-aubergine-text-rgb);
  --ion-color-contrast: var(--ion-color-aubergine-text-contrast);
  --ion-color-contrast-rgb: var(--ion-color-aubergine-text-contrast-rgb);
  --ion-color-shade: var(--ion-color-aubergine-text-shade);
  --ion-color-tint: var(--ion-color-aubergine-text-tint);
}

.ion-color-beige {
  --ion-color-base: var(--ion-color-beige);
  --ion-color-base-rgb: var(--ion-color-beige-rgb);
  --ion-color-contrast: var(--ion-color-beige-contrast);
  --ion-color-contrast-rgb: var(--ion-color-beige-contrast-rgb);
  --ion-color-shade: var(--ion-color-beige-shade);
  --ion-color-tint: var(--ion-color-beige-tint);
}
