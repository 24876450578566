:global(.ios) .checkboxHeader {
  padding-right: calc(var(--ion-safe-area-right, 0px) + 18px);
}

:global(.md) .checkboxHeader {
  padding-right: calc(var(--ion-safe-area-right, 0px) + 16px);
}

:global(.ios) .container {
  --avatar-size: 36px;
}

:global(.md) .container {
  --avatar-size: 40px;
}

.channelIconAvatar {
  overflow: unset;
}

:global(.md) .channelPreviewIcon {
  margin-top: 8px;
  margin-bottom: 8px;
}

:global(.md) .channelPreviewIcon {
  margin-right: 16px;
}

@supports ((-webkit-margin-start: 0) or (margin-inline-start: 0)) or
  (-webkit-margin-start: 0) {
  :global(.md) .channelPreviewIcon {
    margin-right: unset;
    margin-inline-end: 16px;
  }
}

@supports ((-webkit-margin-start: 0) or (margin-inline-start: 0)) or
  (-webkit-margin-start: 0) {
  :global(.ios) .channelPreviewIcon {
    margin-left: unset;
    margin-right: unset;
    margin-inline: 0px 20px;
  }
}

:global(.ios) .channelPreviewIcon {
  margin: 2px 20px 2px 0px;
}

.channelPreviewContainer {
  display: flex;
}

@supports ((-webkit-margin-start: 0) or (margin-inline-start: 0)) or
  (-webkit-margin-start: 0) {
  :global(.md) .channelPreviewContainer {
    padding-left: unset;
    padding-right: unset;
    padding-inline-start: calc(16px + var(--ion-safe-area-left, 0px));
    padding-inline-end: 16px;
  }
}

@supports ((-webkit-margin-start: 0) or (margin-inline-start: 0)) or
  (-webkit-margin-start: 0) {
  :global(.ios) .channelPreviewContainer {
    padding-left: unset;
    padding-right: unset;
    padding-inline-start: calc(20px + var(--ion-safe-area-left, 0px));
    padding-inline-end: 20px;
  }
}

.channelPreviewIcon {
  width: var(--avatar-size);
  height: var(--avatar-size);
  align-self: center;
}

.channelPreviewItem {
  flex-grow: 1;
}

.channelPreviewItem::part(native) {
  padding-left: 0;
}

.channelPreviewItem ion-input {
  --padding-start: 0;
}
