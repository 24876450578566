.channelTitle {
  line-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.channelTitleWrapper {
  display: flex;
}

:global(.ios) .channelTitleWrapper {
  align-items: center;
  justify-content: center;
}

.channelAvatar {
  --avatar-size: 32px;
  height: var(--avatar-size);
  width: var(--avatar-size);
  margin-right: 5px;
  flex-shrink: 0;
}

.clickable {
  cursor: pointer;
}

.noIconOverflow {
  overflow: unset;
}
