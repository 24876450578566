.fill {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background: var(--ion-color-base);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 500ms;
}

.avatarIcon {
  color: var(--ion-color-contrast);
  height: 65%;
  width: 65%;
}
