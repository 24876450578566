.icon {
  color: var(--ion-color-info-card-body-text);
  cursor: pointer;
}

:global(.card-content-ios) .inviteCode,
.inviteCode {
  font-size: 150%;
  text-align: center;
  margin-top: 0.5em;
}

.form,
.formInput {
  --ion-item-background: transparent;
}
