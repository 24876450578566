ion-badge {
  --padding-bottom: 3px; /* Same as padding-top */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--ion-font-family-heading);
}

ion-avatar {
  overflow: hidden;
}
ion-card-title,
ion-card-subtitle {
  font-family: var(--ion-font-family-heading);
}

.ion-margin,
.ion-margin-horizontal {
  -webkit-margin-start: var(--ion-margin, 16px);
  -webkit-margin-end: var(--ion-margin, 16px);
  margin-inline-end: var(--ion-margin, 16px);
  margin-inline-start: var(--ion-margin, 16px);
}
