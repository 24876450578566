.chip {
  border: 1px solid var(--ion-color-aubergine-text);
  --color: var(--ion-color-aubergine-text);
  padding-inline: 20px;
}
.chip:first-of-type {
  margin-left: 1px;
}

.selected {
  --background: var(--ion-color-aubergine-text);
  --color: var(--ion-color-aubergine-text-contrast);
}

.starterKitImage {
  width: 35%;
}

.button {
  padding-inline: 20px;
}

.orderedList {
  margin-top: 0;
  margin-bottom: var(--ion-margin, 16px);
}
