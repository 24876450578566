.avatar {
  --avatar-size: 200px;
  height: var(--avatar-size);
  width: var(--avatar-size);
  margin: calc(-1 * calc(var(--avatar-size) / 2 + var(--ion-padding, 16px)))
    auto 0 auto;
}

.background {
  height: 35%;
}
