@media (prefers-color-scheme: light) {
  .str-chat {
    background: none !important;
  }
}
.str-chat * {
  font-family: var(--ion-font-family) !important;
}

.str-chat__date-separator-date,
.str-chat__message-simple-name {
  font-family: var(--ion-font-family-heading) !important;
}

.str-chat__send-button svg *,
.str-chat__message-simple-status svg * {
  fill: var(--ion-color-primary);
}

.str-chat.str-chat-channel {
  max-height: none;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.str-chat__container {
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.str-chat__message-text-inner {
  color: var(--ion-text-color, #1f2933);
}

.str-chat__message--me.str-chat__message--deleted,
.str-chat__message-simple--me.str-chat__message--deleted {
  padding-right: var(--xl-m);
}

.rfu-dropzone:focus {
  outline: none;
}

.str-chat .str-chat__list {
  background: none !important;
}
.str-chat__modal__inner {
  margin: 0 var(--xl-p);
  width: 100%;
}

.str-chat__modal .str-chat__edit-message-form {
  width: unset;
}

/* do not display emoji picker on edit dialog */
.str-chat__edit-message-form-options .str-chat__input-emojiselect {
  display: none;
}

/* do not show file picker and emoji picker tooltips unless device is desktop */
html:not(.plt-desktop) .str-chat__input-flat .str-chat__tooltip,
html:not(.plt-desktop) .str-chat__edit-message-form-options .str-chat__tooltip {
  display: none !important;
}

.emoji-mart {
  /* This is set `break-word` on `body` by @ionic/react/src/css/structure.scss which causes Safari to render emoji
  with multiple unicode code points as separate characters, where usually one of them is invisible but still occupies
  space, throwing the alignment of the whole picker off. */
  overflow-wrap: normal;
}

.str-chat__message-simple__actions__action--options {
  /* do not display context menu ellipsis icon */
  display: none !important;
}

.str-chat.messaging .str-chat__input-flat {
  padding-bottom: calc(var(--ion-safe-area-bottom, 0) + 8px);
}

.str-chat__input-flat .rfu-file-upload-button {
  position: static;
  padding: 12px;
}
.str-chat__input-flat .rfu-file-upload-button > label {
  height: 14px;
  display: block;
}

.str-chat__message-mention {
  cursor: pointer;
}

.str-chat__input-flat .str-chat__textarea > textarea,
.str-chat__input-flat .str-chat__textarea > textarea:hover,
.str-chat__input-flat .str-chat__textarea > textarea:focus {
  border: none;
  box-shadow: none;
  outline: none;
  font-size: 15px;
  padding: 9px 44px 8px 14px;
  line-height: 22px;
}

.str-chat__input-flat .str-chat__textarea > textarea {
  min-height: auto;
}

@media (prefers-color-scheme: dark) {
  .str-chat__input-flat .str-chat__textarea > textarea,
  .str-chat__input-flat .str-chat__textarea > textarea:hover,
  .str-chat__input-flat .str-chat__textarea > textarea:focus {
    background: rgb(167 167 167 / 20%) !important;
  }
}

.str-chat__input-flat .rfu-file-upload-button svg {
  opacity: 1;
  fill: var(--ion-text-color, #1f2933);
}

.rta__autocomplete.str-chat__emojisearch {
  background: var(--white);
}

.rta__entity {
  /* autocomplete item */
  /* child already has padding, so remove padding from this element */
  padding: 0;
}
.rta__entity:not(.rta__entity--selected) {
  /* prevent button color (apple-system-blue) from being used for text */
  color: var(--ion-text-color, #1f2933);
}

.str-chat__typing-indicator {
  height: 32px;
}

.str-chat__input-flat .str-chat__fileupload-wrapper {
  position: absolute;
  bottom: 4px;
  right: 6px;
}

.str-chat__input-flat-wrapper {
  align-items: flex-end;
}

.str-chat__send-button {
  height: 45px;
}

.str-chat__send-button:focus {
  outline: none;
}

.str-chat__modal {
  box-sizing: border-box;
}

.ios .str-chat__modal {
  padding-top: 44px;
}

.md .str-chat__modal {
  padding-top: 56px;
}

.str-chat__message-attachment .str-chat__modal__close-button {
  color: white;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
}
.str-chat__message-attachment .str-chat__modal__close-button svg {
  fill: white;
}

.str-chat__message-attachment .str-chat__modal__close-button {
  top: var(--ion-safe-area-top, 0);
}

.str-chat__message-attachment .str-chat__modal__inner {
  background: transparent;
  width: 100%;
  height: 100%;
  position: relative;
}

.str-chat__message-attachment .image-gallery,
.str-chat__message-attachment .image-gallery-content,
.str-chat__message-attachment .image-gallery-slide-wrapper,
.str-chat__message-attachment .image-gallery-slide,
.str-chat__message-attachment .image-gallery-swipe,
.str-chat__message-attachment .image-gallery-slides,
.str-chat__message-attachment .image-gallery-slide .image-gallery-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.str-chat__message-attachment .image-gallery-image {
  height: 100%;
  max-height: none;
}
.str-chat__message-attachment .image-gallery-index {
  top: auto;
  bottom: 8px;
  right: 0;
}

.str-chat__message-attachment--img {
  outline: none !important;
}

.str-chat__edit-message-form .rta__autocomplete.str-chat__emojisearch {
  z-index: 0;
}

.image-gallery-fullscreen-button {
  display: none;
}

.str-chat__modal .rta__autocomplete.str-chat__emojisearch {
  /* prevent autocomplete menu from being larger than available space */
  max-height: 200px;
}

.str-chat__reaction-selector {
  background: #262626;
}

.str-chat__user-item--name {
  /* align user item names with their avatars */
  display: flex;
  align-items: center;
}

.str-chat__message-simple-text-inner {
  max-width: calc(100vw - 95px);
}
