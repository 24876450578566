.peopleIcons {
  margin-right: 16px;
  overflow: hidden;
}

.communityResultCardGrid {
  --ion-grid-padding: 0;
}

.communityResultCardGrid ion-col {
  --ion-grid-column-padding: 0;
  padding: 4px 0;
}
