.optionCard {
  background-color: var(--ion-color-banner-body-muted-aubergine);
}
.superTitle {
  color: var(--ion-color-info-card-body-text) !important;
}
.tipBanner {
  --tip-banner-basis: 10px;
  background-color: var(--ion-color-mango);
  color: black;
  border-radius: calc(var(--tip-banner-basis) * 5);
  display: flex;
  justify-content: center;
  font-size: var(--tip-banner-basis);
  align-items: center;
  height: 100%;
  padding: calc(var(--tip-banner-basis) / 2);
  padding-right: var(--tip-banner-basis);
}
.tipBanner ion-icon {
  padding-right: calc(var(--tip-banner-basis) / 3);
  font-size: calc(var(--tip-banner-basis) * 1.5);
}
