.fullscreenContainer {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.starburstBackground {
  --background: linear-gradient(#d06cb4, #fac450);
  background: var(--background);
  color: white;
}
.flexColumn {
  flex-direction: column;
}

.flexRow {
  flex-direction: row;
}

@media (prefers-color-scheme: light) {
  .contentContainer {
    --ion-background-color: #f0f0f0;
  }
}

.card {
  box-shadow: none;
}

.card ion-item:last-child {
  --inner-border-width: 0;
}

.contentContainer {
  --ion-item-background: var(--ion-card-background, #fff);
}

.itemIconAvatar {
  background-color: unset !important;
}

@media (prefers-color-scheme: dark) {
  .modalCard {
    --ion-item-background: var(--ion-background-color);
    --ion-backdrop-color: var(--ion-color-banner-body-muted-aubergine);
    --ion-backdrop-opacity: 1;
  }
}
.flexCenter {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.onboardingContentContainer {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: calc(var(--ion-padding, 16px) * 2);
  font-size: 12pt;
}

.limitMaxWidth {
  max-width: 400px;
  width: 100%;
}
.closeIcon {
  position: relative;
  left: 5px;
  bottom: 15px;
}

.large {
  --avatar-size: 128px;
  height: var(--avatar-size);
  width: var(--avatar-size);
}

.centerContent {
  display: flex;
  align-items: center;
  flex-direction: column;
}
