@font-face {
  font-family: "Kollektif";
  src: url("./assets/fonts/Kollektif/kollektif-bold-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/Kollektif/kollektif-bold-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Kollektif";
  src: url("./assets/fonts/Kollektif/kollektif-bolditalic-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/Kollektif/kollektif-bolditalic-webfont.woff")
      format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Kollektif";
  src: url("./assets/fonts/Kollektif/kollektif-italic-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/Kollektif/kollektif-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Kollektif";
  src: url("./assets/fonts/Kollektif/kollektif-webfont.woff2") format("woff2"),
    url("./assets/fonts/Kollektif/kollektif-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("./assets/fonts/Lato/lato-bold-webfont.woff2") format("woff2"),
    url("./assets/fonts/Lato/lato-bold-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("./assets/fonts/Lato/lato-bolditalic-webfont.woff2") format("woff2"),
    url("./assets/fonts/Lato/lato-bolditalic-webfont.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Lato";
  src: url("./assets/fonts/Lato/lato-italic-webfont.woff2") format("woff2"),
    url("./assets/fonts/Lato/lato-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Lato";
  src: url("./assets/fonts/Lato/lato-regular-webfont.woff2") format("woff2"),
    url("./assets/fonts/Lato/lato-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
