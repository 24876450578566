.translationWrapper {
  position: relative;
  overflow: hidden;
  min-height: 45%;
}
.illustration {
  max-width: 80% !important;
  object-fit: contain;
  position: absolute;
  left: 50%;
  bottom: -10%;
  transform: translateX(-50%);
}
.translationIllustration {
  opacity: 0;
  animation: opacity 9s ease-in-out infinite;
}
@keyframes opacity {
  0% {
    opacity: 0;
  }
  33% {
    opacity: 1;
  }
  66% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
