.mdl-button--raised.mdl-button--colored {
  background-color: var(--ion-color-primary) !important;
}

#firebaseui_container * {
  font-family: var(--ion-font-family) !important;
}

#firebaseui_container h1 {
  font-family: var(--ion-font-family-heading) !important;
}
