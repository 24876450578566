.slideContainer {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.media {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: var(--ion-color-beige);
  max-height: 45%;
  min-height: fit-content;
}

@media (prefers-color-scheme: dark) {
  .media {
    background-color: var(--ion-background-color);
  }
}

.content {
  flex-grow: 1;
  padding-bottom: 0;
  padding: var(--ion-padding, 16px) calc(2 * var(--ion-padding, 16px));
}

.footer {
  padding-left: var(--ion-padding, 16px);
  padding-right: var(--ion-padding, 16px);
  background-color: var(--ion-card-background, #fff);
  padding-bottom: calc(var(--ion-padding, 16px) + var(--ion-safe-area-bottom));
}

.buttons {
  display: flex;
  flex-direction: row;
}

.buttons ion-button {
  flex-grow: 1;
}

.content,
.footer {
  background-color: var(--ion-card-background, #fff);
}

.content {
  --ion-item-background: var(--ion-card-background, #fff);
}

.form {
  height: 100%;
  width: 100%;
}
