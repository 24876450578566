.activeBullet {
  --bullet-background: var(--ion-color-aubergine-fill);
  background-color: var(--bullet-background);
  opacity: 1;
}

.slideHeader {
  text-align: right;
}
.slideLabel {
  font-family: var(--ion-font-family-heading);
  pointer-events: none;
}
.cardTextContainer {
  text-align: left;
}
.illustrationContainer {
  margin: auto auto;
  max-width: 55%;
}
.slideContainer:first-of-type .illustrationContainer {
  max-width: unset;
}

.navigationLayout {
  display: flex;
  align-items: center;
}

.navArrow {
  color: var(--ion-color-aubergine-fill);
  position: relative;
  right: -20px;
  width: 0;
}
.navArrow:first-of-type {
  left: -20px;
}
