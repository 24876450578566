.iconColor {
  --avatar-size: 40px;
  height: var(--avatar-size);
  width: var(--avatar-size);
}

.modalHeader {
  --ion-toolbar-background: var(--ion-backgorund-color);
}

.iconContainer {
  --avatar-size: 50px;
  width: var(--avatar-size);
  height: var(--avatar-size);
  flex-grow: 0;
  margin: 8px;
}

.iconAvatar {
  height: var(--avatar-size);
  width: var(--avatar-size);
}

.iconPreview {
  --avatar-size: 100px;
  height: var(--avatar-size);
  width: var(--avatar-size);
}

.iconPreviewWrapper,
.colorColumn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.solidCheckbox {
  height: 32px;
  width: 32px;
  --background: var(--ion-color-base);
  --border-color: var(--ion-color-base);
}
