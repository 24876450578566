:global(.ios) .cardHeader ion-card-title {
  font-size: 20px;
}

.cardContent {
  display: flex;
  flex-direction: column;
}

.cardContent ion-text {
  color: var(--ion-color-info-card-body-text);
}
.cardContent ion-button {
  align-self: flex-end;
}

.illustrationContentLayout {
  display: flex;
}

.illustrationContentLayout .content {
  flex: 1 1 100%;
}
.cardHeader {
  display: flex;
  justify-content: space-between;
}

.icon {
  flex-shrink: 0;
}
